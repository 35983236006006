// gray scale
@each $level, $value in $grays {
    .text-gray-#{$level} {
        color: $value !important;
    }
    .bg-gray-#{$level} {
        background-color: $value !important;
    }
}

.text-black {
    color: $black;
}

.bg-black {
    background-color: $black;
}

.text-gray {
    color: $gray-600;
}

.bg-gray {
    background-color: $gray-600;
}


@each $color, $value in $theme-colors  {

    $rgba: rgba($value, .17);
    $bg:   mix(opacify($rgba, 1), white, alpha($rgba) * 100%);

    .btn-#{$color}-light {

        // $bg: lighten($value, 30%);
        @include button-variant($bg, $bg);

        // color: $value !important;
        color: darken($color: $value, $amount: 5%) !important;
    }

    .bg-#{$color}-light {
        background-color: $bg !important;
    }

    .text-#{$color}-dark {
        color: darken($color: $value, $amount: 5%) !important;
    }
}