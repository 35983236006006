#tools-nav,
#editor-tab .nav-link{
    height: 3.5rem;
}

#editor-tab .nav-link{
    color: #333;
    font-weight: 500;
    font-size: 0.71rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    border-top: none;
    border-radius: 0px;
}

#full-screen.active,
#device-nav > button.active{
    &:hover{
        background-color: $primary !important;
        border-color: $primary !important;
    }
    color: #fff !important;
    background-color: $primary !important;
    border-color: $primary !important;
}

.fixed-content{
    height: calc(100% - 3.5rem);
}

.device{
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.5rem;
    overflow: hidden;
    /* background: var(--gray); */
    /* padding: 1.8rem 2px 2px 2px; */
    border: 3px solid var(--gray);
    border-top: 1.8rem solid var(--gray);
    transition:width 300ms ease-in-out, height 300ms ease-in-out;
}

.full-screen{
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: white;
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important;
    z-index: 1000;
}

.device.responsive{
    height: 100%;
}

.device.tablet{
    width: 768px;
}

.device.phone{
    width: 375px;
}

.device .device-content{
    width: 100%;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 120px);
}

.device-type {
    text-transform: capitalize;
}

.color-picker{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    font-weight: 500;
    padding: .3rem 0 .3rem .75rem !important;
    background-color: var(--light);
    position: relative;
}

.color-picker label{
    margin: 0;
}

.color-selector{
    display:none;
}

.dropdown-colorselector{
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background: #ffffff;
    padding: 0.4rem;
    margin-right: -2px;
}

.dropdown-colorselector > a::after{
    display: none;
}

.dropdown-colorselector > .dropdown-menu{
    min-width: 252px;
    max-width: 252px;
    right: -14px !important;
    top: auto !important;
    bottom: 39px;
    left: auto !important;
    transform: none !important;
}

.dropdown-menu.dropdown-caret:before{
    left: auto;
    right: 20px;
    top: auto;
    bottom: -7px;
    border-bottom: none;
    border-top: 7px solid rgba(0,0,0,.2);
}
.dropdown-menu.dropdown-caret:after{
    left: auto;
    right: 21px;
    top: auto;
    bottom: -6px;
    border-bottom: none;
    border-top: 6px solid #FFF;
}
.dropdown-colorselector>.dropdown-menu>li>.color-btn.selected:after{
    content: '\e982';
    font-family: 'simpleiconic' !important;
    font-size: 12px;
}
.btn-colorselector,
.dropdown-colorselector>.dropdown-menu>li>.color-btn{
    border: 1px solid rgb(51 51 51 / 20%);
}

#form-widget .form-control{
    box-shadow: none;
}