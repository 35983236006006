@import '~jquery-confirm/css/jquery-confirm.css';

.jconfirm.jconfirm-bootstrap {
    .jconfirm-bg {
        background: rgba(61, 71, 95, 0.65);
    }

    .jconfirm-box {
        
        @extend .shadow;

        div.jconfirm-closeIcon {
            font-size: 25px!important;
            border-left: 1px solid #9fa7b5;
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding-left: 5px;
            &:after{
                content: 'ESC';
                font-size: 11px;
                position: absolute;
                left: -25px;
            }
        }

        div.jconfirm-title-c {
            font-weight: bold;
            font-size: 1.25rem;
        }

        .jconfirm-buttons {
            border: none;
            button.btn {
                
                &-default,
                &-light{
                    background-color: transparent;
                    border: 1px solid transparent;
                    &:hover{
                        background-color: $white !important;
                        border-color: darken($gray-200, 2%) !important;
                    }
                }
            }
        }
    }
}