.btn {

    letter-spacing: 0.025em;
    
    &:hover {
        box-shadow: 0 5px 12px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
        // box-shadow: 0 5px 10px rgb(50 50 93 / 8%), 0 2px 3px rgb(0 0 0 / 5%);
    }
    
    &-link,
    &[disabled] {
        box-shadow: none;
        transform: none;
    }

    // &-success,
    // &-success:hover,
    // &-danger,
    // &-danger:hover {
    //     color: $white;
    // }

    // &-default{
    //     background-color: $white;
    //     border-color: $gray-200;
    //     &:hover{
    //         border-color: darken($gray-200, 2%) !important;
    //     }
    // }

    &-default{
        // background-color: transparent;
        // border-color: transparent;
        // box-shadow: none;
        &:hover{
            // background-color: $white !important;
            border-color: darken($border-color, 2%) !important;
        }
    }

    &-loading {
        position: relative;
        pointer-events: none;
        color: transparent !important;

        &:after {
            content: "";
            display: block;
            height: 1em;
            width: 1em;
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));
            animation: spinAround 500ms infinite linear;
            border: 2px solid opacify($white, 0.65);
            border-radius: 50%;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
        }

        &-dark {
            @extend .btn-loading;

            &:after {
                border-color: opacify($black, 0.65);
            }
        }
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@include media-breakpoint-up (md){
    .btn-mw-lg {
        max-width: 150px;
    }
}