.select2-dropdown{
    @extend .shadow-sm;
}

.select2-selection__clear{
    padding-left: 0.18em;
    padding-top: 0.15em;
}

.select2-result{
    &-title {
        font-weight: bold;
        word-wrap: break-word;
        line-height: 1.1;
    }

    &-description {
        @extend .text-muted;
        font-size: 13px;
    }

    &s__option--highlighted{
        .select2-result-title {
            color: white !important;
        }
        .select2-result-description {
            color: lighten($color: $primary, $amount: 30%) !important;
        }
    }

    &-avatar {
        width: 50px;
        height: 50px;
        
        img {
            @extend .rounded;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.select2-selection {
    &:active,
    &:hover,
    &:focus {
        box-shadow: 0 3px 9px rgba(50, 50, 9, 0),  3px 4px 8px rgba(94, 114, 228, .1);
    }
    
    box-shadow: $box-shadow-sm;

    &-avatar {
        @extend .rounded;
        width: 25px;
        height: 25px;
        margin: auto 0px;
        object-fit: cover;
    }
    &-title {
        margin-left: 5px;
    }
}