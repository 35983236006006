table.dataTable {
    width: 100% !important;
    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
}

table.dataTable thead th {
    border-color: $table-border-color;
    background-color: #f6f9fc;
    font-size: .65rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid $table-border-color;
    border-top: 1px solid $table-border-color;
    overflow: hidden;
    white-space: nowrap;
}

table.dataTable thead th,
table.dataTable tfoot th {
    font-weight: bold;
}

table.dataTable thead th,
table.dataTable thead td {
    padding: 10px 18px;
    /* border-bottom: 1px solid #111 */
}

table.dataTable thead th:active,
table.dataTable thead td:active {
    outline: none
}

table.dataTable tfoot th,
table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    /* border-top: 1px solid #111 */
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    *cursor: hand;
    background-repeat: no-repeat;
    background-position: center right;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting {
    padding-right: 30px;
}

table.dataTable thead>tr>th:active,
table.dataTable thead>tr>td:active {
    outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
    cursor: pointer;
    position: relative;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    bottom: 0.9em;
    display: block;
    opacity: 0.20;
}

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
    /* right: 1em;
	content: "\2191" */
    right: 1em;
    top: calc(50% - 6px);
    content: '';
    width: 2px;
    height: 2px;
    border-style: solid;
    border-width: 0 3px 5px 3px;
    border-color: transparent transparent #8898aa transparent;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
    /* right: 0.5em;
    content: "\2193" */
    right: 1em;
    bottom: calc(50% - 6px);
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 3px 0 3px;
    border-color: #8898aa transparent transparent transparent;
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
    opacity: 1;
}

table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
    opacity: 0;
}

.dataTables_scrollHeadInner{
    background: #f6f9fc;
    box-shadow: inset 1px 0px 0px 1px $table-border-color;
}

td.sorting_1 {
    font-weight: bold;
}

.dataTables_scroll {
    margin-left: -6px;
    margin-right: -6px;
}

.dataTables_filter label {
    font-size: 0;
    position: relative;
}

.dataTables_filter label:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23b7b9cc' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
    background-repeat: no-repeat;
    position: absolute;
    background-position-y: center;
    font-size: 0.75rem;
    height: 100%;
    width: 30px;
    margin-left: 5px;
}

.dataTables_filter input[type='search'] {
    font-size: 0.9rem;
    padding-left: 28px !important;
}


table.dataTable tbody tr {
    background-color: #ffffff;
}

table.dataTable tbody tr:hover {
    background-color: #fafbfc;
}

table.dataTable tbody tr.selected {
    background-color: #B0BED9;
}

table.dataTable tbody th,
table.dataTable tbody td {
    /* padding: 8px 10px; */
    padding: 0.75rem;
    border-top: 1px solid $table-border-color;
}


table.dataTable tbody tr:first-child th,
table.dataTable tbody tr:first-child td {
    border-top: 0;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
    border-top: 1px solid #ddd;
}

table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td,
table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
    border-top: none;
}

table.dataTable.cell-border tbody th,
table.dataTable.cell-border tbody td {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
    border-left: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
    border-top: none;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
    background-color: #f9f9f9;
}

table.dataTable.stripe tbody tr.odd.selected,
table.dataTable.display tbody tr.odd.selected {
    background-color: #acbad4;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
    background-color: #f6f6f6;
}

table.dataTable.hover tbody tr:hover.selected,
table.dataTable.display tbody tr:hover.selected {
    background-color: #aab7d1;
}

table.dataTable.order-column tbody tr>.sorting_1,
table.dataTable.order-column tbody tr>.sorting_2,
table.dataTable.order-column tbody tr>.sorting_3,
table.dataTable.display tbody tr>.sorting_1,
table.dataTable.display tbody tr>.sorting_2,
table.dataTable.display tbody tr>.sorting_3 {
    background-color: #fafafa;
}

table.dataTable.order-column tbody tr.selected>.sorting_1,
table.dataTable.order-column tbody tr.selected>.sorting_2,
table.dataTable.order-column tbody tr.selected>.sorting_3,
table.dataTable.display tbody tr.selected>.sorting_1,
table.dataTable.display tbody tr.selected>.sorting_2,
table.dataTable.display tbody tr.selected>.sorting_3 {
    background-color: #acbad5
}

table.dataTable.display tbody tr.odd>.sorting_1,
table.dataTable.order-column.stripe tbody tr.odd>.sorting_1 {
    background-color: #f1f1f1;
}

table.dataTable.display tbody tr.odd>.sorting_2,
table.dataTable.order-column.stripe tbody tr.odd>.sorting_2 {
    background-color: #f3f3f3;
}

table.dataTable.display tbody tr.odd>.sorting_3,
table.dataTable.order-column.stripe tbody tr.odd>.sorting_3 {
    background-color: whitesmoke
}

table.dataTable.display tbody tr.odd.selected>.sorting_1,
table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_1 {
    background-color: #a6b4cd;
}

table.dataTable.display tbody tr.odd.selected>.sorting_2,
table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_2 {
    background-color: #a8b5cf;
}

table.dataTable.display tbody tr.odd.selected>.sorting_3,
table.dataTable.order-column.stripe tbody tr.odd.selected>.sorting_3 {
    background-color: #a9b7d1;
}

table.dataTable.display tbody tr.even>.sorting_1,
table.dataTable.order-column.stripe tbody tr.even>.sorting_1 {
    background-color: #fafafa;
}

table.dataTable.display tbody tr.even>.sorting_2,
table.dataTable.order-column.stripe tbody tr.even>.sorting_2 {
    background-color: #fcfcfc;
}

table.dataTable.display tbody tr.even>.sorting_3,
table.dataTable.order-column.stripe tbody tr.even>.sorting_3 {
    background-color: #fefefe;
}

table.dataTable.display tbody tr.even.selected>.sorting_1,
table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_1 {
    background-color: #acbad5;
}

table.dataTable.display tbody tr.even.selected>.sorting_2,
table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_2 {
    background-color: #aebcd6;
}

table.dataTable.display tbody tr.even.selected>.sorting_3,
table.dataTable.order-column.stripe tbody tr.even.selected>.sorting_3 {
    background-color: #afbdd8;
}

table.dataTable.display tbody tr:hover>.sorting_1,
table.dataTable.order-column.hover tbody tr:hover>.sorting_1 {
    background-color: #eaeaea;
}

table.dataTable.display tbody tr:hover>.sorting_2,
table.dataTable.order-column.hover tbody tr:hover>.sorting_2 {
    background-color: #ececec;
}

table.dataTable.display tbody tr:hover>.sorting_3,
table.dataTable.order-column.hover tbody tr:hover>.sorting_3 {
    background-color: #efefef;
}

table.dataTable.display tbody tr:hover.selected>.sorting_1,
table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_1 {
    background-color: #a2aec7;
}

table.dataTable.display tbody tr:hover.selected>.sorting_2,
table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_2 {
    background-color: #a3b0c9;
}

table.dataTable.display tbody tr:hover.selected>.sorting_3,
table.dataTable.order-column.hover tbody tr:hover.selected>.sorting_3 {
    background-color: #a5b2cb;
}

table.dataTable.no-footer {
    border-bottom: 1px solid $table-border-color;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
    white-space: nowrap;
}

table.dataTable.compact thead th,
table.dataTable.compact thead td {
    padding: 4px 17px 4px 4px;
}

table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
    padding: 4px;
}

table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
    padding: 4px;
}

table.dataTable th.dt-left,
table.dataTable td.dt-left {
    text-align: left;
}

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
    text-align: center;
}

table.dataTable th.dt-right,
table.dataTable td.dt-right {
    text-align: right;
}

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
    text-align: justify;
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
    white-space: nowrap;
}

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
    text-align: left;
}

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
    text-align: center;
}

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
    text-align: right;
}

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
    text-align: justify;
}

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
    white-space: nowrap;
}

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
    text-align: left;
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
    text-align: center;
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
    text-align: right;
}

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
    text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
    white-space: nowrap;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
    box-sizing: content-box;
}

.dataTables_wrapper {
    position: relative;
    clear: both;
    *zoom: 1;
    zoom: 1;
    background: #fff;
    padding: 6px;
}

.dataTables_wrapper .dataTables_length {
    float: left;
}

.dataTables_wrapper .dataTables_filter {
    float: right;
    text-align: right;
}

.dt-buttons {
    position: relative;
    /* display: inline-block; */
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -3px;
}

.dt-button {
    position: relative;
    display: inline-block;
    background-color: $secondary;
    border: 1px solid $secondary;
    color: #fff;
    padding: 0.15rem 0.5rem;
    border-radius: 0.15rem;
    cursor: pointer;
    height: 30px;
    font-size: 0.90em;
}

/* .dt-button:not(.buttons-collection) {
	font-size: 0.85em;
} */

.dt-buttons > .dt-button{
    margin: 3px;
    /* box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); */
}

.dt-buttons > .dt-button span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dt-button:focus:not(.buttons-collection),
.dt-button:active:not(.buttons-collection) {
    border: 1px solid $secondary !important;
}

.dt-button.active {
    background-color: $primary !important;
    border: 1px solid $primary !important;
}

.dt-button.active:focus:not(.buttons-collection),
.dt-button.active:active:not(.buttons-collection) {
    border: 1px solid $primary !important;
}

/* 
.dt-button-background{
    background: rgba(0, 0, 0, 0.40);
} */

.dt-button-background.dt-pointer-none{
    pointer-events: none;
}

.dt-button-background.dt-pointer-all{
    pointer-events: all;
    cursor: pointer;
}

.dt-button-collection .buttons-columnVisibility span:before {
    content: '';
    background-color: transparent;
    background: #fff;
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid #c8ccd4;
    border-radius: 2px;
    vertical-align: middle;
    margin-right: 10px;
    margin-left: -15px;
}

.dt-button-collection .buttons-columnVisibility span:after {
	content: '';
	position: absolute;
	top: 9px;
    left: 14px;
	width: 5px;
	height: 11px;
	opacity: 0;
	-webkit-transform: rotate(45deg) scale(0);
	transform: rotate(45deg) scale(0);
	border-right: 2px solid #fff;
	border-bottom: 2px solid #fff;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: transform 0.3s ease;
}

.dt-button-collection .buttons-columnVisibility.active span:before {
	background: $primary;
	border-color: $primary;
}

.dt-button-collection .buttons-columnVisibility.active span:after {
	opacity: 1;
	-webkit-transform: rotate(45deg) scale(0.9);
	transform: rotate(45deg) scale(0.9);
}

.buttons-collection {
    margin-left: 0px !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+);
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
    background-size: 20px;
    padding-right: 20px !important;
}

div.dt-button-collection {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1003;
    padding: 0.5rem 0;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    width: 100%;
    width: min-content;
    -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5px;
}

.dt-button-collection .dt-button {
    width: 100%;
    color: $dark;
    background: transparent;
    border: none !important;
    padding: 0.25rem 1.5rem;
    clear: both;
    white-space: nowrap;
    font-weight: 400;
    font-size: 0.9rem;
    border-radius: 0px;
    text-align: left;
}

.dt-button-collection .dt-button:focus,
.dt-button-collection .dt-button:hover {
    color: $dark;
    background-color: #f8f9fa !important;
    border: none !important;
}

.dt-button-collection .dt-button.active,
.dt-button-collection .dt-button:active {
    color: #fff;
    background-color: $primary !important;
    border: none !important;
}

div.dt-button-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1001
}

.buttons-collection,
.dataTables_length select,
.dataTables_wrapper .dataTables_filter input {
    /* margin-left: 0.5em; */
    background-color: #fff;
    border: 1px solid $table-border-color;
    padding: 0.15rem 0.5rem;
    border-radius: 0.15rem;
    color: $dark;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.buttons-collection:hover,
.dataTables_length select:hover,
.dataTables_wrapper .dataTables_filter input:hover {
    border: 1px solid $table-border-color;
}

.buttons-collection:focus,
.buttons-collection:active,
.dataTables_length select:focus,
.dataTables_length select:active,
.dataTables_wrapper .dataTables_filter input:focus,
.dataTables_wrapper .dataTables_filter input:active {
    outline: none !important;
    border: 1px solid $primary !important;
}

.dataTables_wrapper .dataTables_info {
    clear: both;
    float: left;
    padding-top: 0.755em;
    font-size: 0.85em;
}

.dataTables_wrapper .dataTables_paginate {
    float: right;
    text-align: right;
    padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 0.3em 1em;
    margin-left: 2px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    *cursor: hand;
    color: #333 !important;
    border: 1px solid transparent;
    border-radius: 2px;
    outline: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #ffffff !important;
    background-color: $primary;
    -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #666 !important;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background-color: #EEEEEE;
}


.dataTables_wrapper .dataTables_paginate .ellipsis {
    padding: 0 1em;
}

.dataTables_wrapper .dataTables_processing {
    /* position: absolute; */
    top: 50%;
    left: 50%;
    width: 100%;
    height: 40px;
    margin-left: -50%;
    margin-top: -25px;
    padding-top: 20px;
    text-align: center;
    font-size: 1.2em;
    background-color: white;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
    /* color: #333; */
    color: #7d8e9c;
}


.dataTables_wrapper .dataTables_scroll {
    clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
    *margin-top: -1px;
    -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td {
    vertical-align: middle;
    white-space: nowrap;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>th>div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>thead>tr>td>div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>th>div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody>table>tbody>tr>td>div.dataTables_sizing {
    height: 0;
    overflow: hidden;
    margin: 0 !important;
    padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid $table-border-color;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody>table {
    border-bottom: none;
}

.dataTables_wrapper:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
}

@media screen and (max-width: 767px) {

    .dataTables_wrapper .dataTables_info,
    .dataTables_wrapper .dataTables_paginate {
        float: none;
        text-align: center;
    }

    .dataTables_wrapper .dataTables_paginate {
        margin-top: 0.5em;
    }
}

@media screen and (max-width: 640px) {

    .dataTables_wrapper .dataTables_length,
    .dataTables_wrapper .dataTables_filter {
        float: none;
        text-align: center;
    }
}

.dataTables_processing {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    background: none;
}

.table-processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-left: -30px;
    background-color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 90;
    border-radius: 50%;
}

.table-processing>.load-animation {
    width: 24px;
    height: 24px;
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 2px $primary;
    border-radius: 50%;
    position: relative;
}

.table-processing>.load-animation:after,
.table-processing>.load-animation:before {
    position: absolute;
    content: "";
    background-color: $primary;
}

.table-processing>.load-animation:after {
    width: 10px;
    height: 2px;
    top: 11px;
    left: 11px;
    -webkit-transform-origin: 1px 1px;
    -moz-transform-origin: 1px 1px;
    transform-origin: 1px 1px;
    -webkit-animation: minhand 2s linear infinite;
    -moz-animation: minhand 2s linear infinite;
    animation: minhand 2s linear infinite;
}

.table-processing>.load-animation:before {
    width: 8px;
    height: 2px;
    top: 11px;
    left: 11px;
    -webkit-transform-origin: 1px 1px;
    -moz-transform-origin: 1px 1px;
    transform-origin: 1px 1px;
    -webkit-animation: hrhand 8s linear infinite;
    -moz-animation: hrhand 8s linear infinite;
    animation: hrhand 8s linear infinite;
}

@-webkit-keyframes minhand {
    0% {
        -webkit-transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@-moz-keyframes minhand {
    0% {
        -moz-transform: rotate(0deg)
    }

    100% {
        -moz-transform: rotate(360deg)
    }
}

@keyframes minhand {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes hrhand {
    0% {
        -webkit-transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@-moz-keyframes hrhand {
    0% {
        -moz-transform: rotate(0deg)
    }
    100% {
        -moz-transform: rotate(360deg)
    }
}

@keyframes hrhand {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@media screen and (max-width: 640px) {

    .dataTables_wrapper .dataTables_filter{
        margin-top: 5px;
    }
    .dataTables_wrapper .dataTables_filter label,
    .dataTables_wrapper .dataTables_filter input{
        width: 100%;
    }
}

/* CheckBox Column*/
table.dataTable.dt-checkboxes-select tbody tr,
table.dataTable thead th.dt-checkboxes-select-all,
table.dataTable tbody td.dt-checkboxes-cell {
    cursor: pointer;
}

table.dataTable thead th.dt-checkboxes-select-all,
table.dataTable tbody td.dt-checkboxes-cell {
    text-align: center;
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
    margin-left: 0.5em;
}

@media screen and (max-width: 640px) {

    div.dataTables_wrapper span.select-info,
    div.dataTables_wrapper span.select-item {
        margin-left: 0;
        display: block;
    }
}

th.dt-checkboxes-select-all input[type='checkbox'],
td .dt-checkboxes {
	width: 16px;
    height: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    position: relative;
    cursor: pointer;
}

th.dt-checkboxes-select-all input[type='checkbox']:before,
td .dt-checkboxes:before {
	content: '';
	background-color: transparent;
	background: #fff;
	width: 16px;
	height: 16px;
	display: inline-block;
	position: absolute;
	border: 1px solid #c8ccd4;
	border-radius: 2px;
	vertical-align: middle;
}

th.dt-checkboxes-select-all input[type='checkbox']:after,
td .dt-checkboxes:after {
	content: '';
	position: absolute;
	top: 1px;
	left: 6px;
	width: 5px;
	height: 11px;
	opacity: 0;
	-webkit-transform: rotate(45deg) scale(0);
	transform: rotate(45deg) scale(0);
	border-right: 2px solid #fff;
	border-bottom: 2px solid #fff;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: transform 0.3s ease;
}

th.dt-checkboxes-select-all input[type='checkbox']:checked:before,
td .dt-checkboxes:checked:before {
	background: $primary;
	border-color: $primary;
}

th.dt-checkboxes-select-all input[type='checkbox']:checked:after,
td .dt-checkboxes:checked:after {
	opacity: 1;
	-webkit-transform: rotate(45deg) scale(0.9);
	transform: rotate(45deg) scale(0.9);
}

th.dt-checkboxes-select-all input[type='checkbox']:indeterminate:after,
td .dt-checkboxes:indeterminate:after {
	content: '';
	position: absolute;
	top: 7px;
    left: 2px;
    width: 12px;
    height: 2px;
	opacity: 0;
	border: 0px;
	-webkit-transform: none;
	transform: none;
	border-bottom: 2px solid $dark;
	-webkit-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
}

th.dt-checkboxes-select-all input[type='checkbox']:indeterminate:after,
td .dt-checkboxes:indeterminate:after {
	opacity: 1;
}

.xs-column{
    min-width: 50px;
    max-width: 50px;
}
.sm-column{
    min-width: 150px;
    max-width: 150px;
}
.md-column{
    min-width: 250px;
    max-width: 250px;
}
.lg-column{
    min-width: 400px;
    max-width: 400px;
}
.xl-column{
    min-width: 500px;
    max-width: 500px;
}