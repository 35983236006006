.cursor-pointer {
	cursor: pointer;
}

.cursor-na {
	cursor: not-allowed;
}

.cursor-zoom-in {
	cursor: zoom-in;
}

.cursor-zoom-out {
	cursor: zoom-out;
}

.pointer-none {
	pointer-events: none;
}