.input-group {
    &.input-group-icon {
        .form-control {
            border-radius: $border-radius;
            padding-left: calc(1.6em + 0.75rem + 2px);
            position: relative;
        }
  
        .form-control-icon {
            position: absolute;
            z-index: 5;
            display: block;
            width: calc(1.6em + 0.75rem + 2px);
            height: calc(1.6em + 0.75rem + 2px);
            text-align: center;
            pointer-events: none;
            color: $gray-500;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                stroke: $gray-500;
            }
        }
    }
}

.form-control {
    &:active,
    &:hover,
    &:focus {
        // box-shadow: $box-shadow;
        box-shadow: 0 3px 9px rgba(50, 50, 9, 0),  3px 4px 8px rgba(94, 114, 228, .1);
    }

    box-shadow: $box-shadow-sm;
}

.was-validated .form-control:invalid, 
.form-control.is-invalid{
    background-image: none;
}

.form-control[readonly] {
    background-color: $white;
}

label{
    margin-bottom: 0.25rem;
}