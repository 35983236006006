.dropdown {
    .dropdown-menu {
        box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
        // border-color: rgba(0, 0, 0, 0.03);
        &.fade-up {
            opacity: 0;
            display: block;
            visibility: hidden;
            margin-top: 10px;
            transition: margin-top .25s, opacity .25s, visibility .25s;
        }
    }
    &.show{
        .dropdown-menu.fade-up {
            opacity: 1;
            visibility: visible;
            margin-top: 0px;
        }
    }
}