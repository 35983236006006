.datepicker{
    font-family: inherit !important;
    margin-top: -5px;
    &--pointer{
        display: none;
    }
    &--day-name{
        font-weight: bold;
        color: inherit !important;
    }
    &--cell{
        &.-selected-{
            background: $primary !important;
            &.-current-{
                color: #fff !important;
            }
        }
        &.-current-{
            color: $primary !important;
        }
    }
}

.datepickers-container{
    z-index: 2000;
}
