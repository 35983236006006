// Toggler Menu
.sidenav-toggler .sidenav-toggler-inner,
.sidenav-toggler .sidenav-toggler-line {
    width: 18px;
    transition: all 0.15s ease;
}

.sidenav-toggler {
    cursor: pointer;
    padding: 3px;

    .sidenav-toggler-inner {
        position: relative;
        // transform: scale(0.8);
    }

    &.active {
        .sidenav-toggler-line {
            width: 18px !important;
            // transform: none !important;
        }
    }

    .sidenav-toggler-line {
        position: relative;
        display: block;
        height: 2px;
        float: right;
        background-color: $dark;

        &:first-child,
        &:last-child {
            width: 13px;
            // transform: translateX(5px);
        }

        &:not(:last-child) {
            margin-bottom: 3px;
        }
    }
}

// Topbar
.topbar {
    height: $topbar-base-height;
    border-top: 3px solid #005fed;
    z-index: 10;
}

.brand-section {
    height: $topbar-base-height;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    padding: 0.5rem 0;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    color: $body-color;
    width: calc(#{$sidebar-base-width} - 1rem);

    &:hover {
        color: $body-color;
        text-decoration: none;
    }

    &-text {
        display: inline;
        white-space: nowrap;
        line-height: 1.35;
    }

    &-icon img {
        width: 150px;
    }
}

@include media-breakpoint-down (md) {
    .brand-section {
        width: calc(#{$sidebar-base-width} - 1rem);
    }
}

.navbar-dark {
    .sidenav-toggler-line {
        background-color: $white;
    }

    .brand-section {
        color: $white;

        &:hover {
            color: $white;
        }
    }
}

.avatar {
    width: 1.625rem;
    height: 1.625rem;
    font-size: 0.54167rem;
}

.avatar-sm {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.83333rem;
}

.avatar-lg {
    width: 4rem;
    height: 4rem;
    font-size: 1.33333rem;
}

.avatar-xl {
    width: 5.125rem;
    height: 5.125rem;
    font-size: 1.70833rem;
}

.avatar-xxl {
    width: 5.125rem;
    height: 5.125rem;
    font-size: 1.70833rem;
}

@media (min-width: 768px) {
    .avatar-xxl {
        width: 8rem;
        height: 8rem;
        font-size: 2.66667rem;
    }
}
