.loading{
    
    &:after {
        content: "";
        display: inline-block;
        height: 0.8em;
        width: 0.8em;
        // position: absolute;
        left: calc(50% - (0.8em / 2));
        top: calc(50% - (0.8em / 2));
        animation: spinAround 500ms infinite linear;
        border: 2px solid opacify($white, 0.65);
        border-radius: 50%;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
    }

    &-dark {
        @extend .loading;

        &:after {
            border-color: opacify($black, 0.65);
        }
    }
}