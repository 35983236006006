.notyf {
    &__toast {
        max-width: 350px;
        border-radius: $border-radius;
        box-shadow: rgba(52, 58, 64, 0.15) 0px 1px 10px 0px,
            rgba(52, 58, 64, 0.1) 0px 6px 12px 0px, 
            rgba(52, 58, 64, 0.12) 0px 6px 15px -2px;
        &--lower {
            margin-top: 6px;
        }
    }

    &__dismiss-btn {
        background: transparent;
        border: none;
        outline: none;

        &:focus {
            border: none;
            outline: none;
        }
    }
}