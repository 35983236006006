// Fonts
@import '~remixicon/fonts/remixicon.css';
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700&display=swap');

// Bootstrap
@import 'variables';
@import "~bootstrap/scss/bootstrap";

// Utilities
@import 'utilities/border';
@import 'utilities/centering';
@import 'utilities/colors';
@import 'utilities/cursor';
@import 'utilities/shadows';

// Componentes
@import 'components/sidebar';
@import 'components/navbar';
@import 'components/forms';
@import 'components/custom-forms';
@import 'components/buttons';
@import 'components/loading';
@import 'components/dropdown';

@import '~swiper/swiper-bundle.min.css';

// Select 2
@import '~select2/src/scss/core.scss';
@import '~@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4';
@import 'plugins/select2';

// DateTime Picker
@import '~air-datepicker/dist/css/datepicker.min.css';
@import 'plugins/datepicker';

// SimpleBar
@import '~simplebar/dist/simplebar.css';

// notyf
@import '~notyf/notyf.min.css';
@import 'plugins/notyf';

// Animate
@import '~animate.css/animate.min.css';

@import 'plugins/datatable';
@import 'plugins/jquery-confirm';
@import 'plugins/bootstrap-colorselector.min.css';

//Views
@import 'views/widgets';

//Cropper
@import '~cropperjs/dist/cropper.min.css';

// Fonts
@import url('https://fonts.bunny.net/css?family=Nunito');

//Datatables
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import '~datatables.net-select-bs4/css/select.bootstrap4.css';
@import '~datatables.net-searchpanes-bs4/css/searchPanes.bootstrap4.css';

body {
    font-display: swap;
    background: $gray-gradient;
}

.font-size-small {
    font-size: 0.8125rem !important;
}

#mainContainer {
    display: flex;
    flex-direction: row;
    height: calc(100% - 4rem);

    #content {
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.no-transition,
.no-transition * {
    transition: none !important;
}

.toggle-password {
    top: 1px;
    right: 1px;
    background: #fff;
    height: calc(1.6em + 0.75rem);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px $border-radius $border-radius 0px;
    box-shadow: none !important;
}

.password-input::-ms-clear,
.password-input::-ms-reveal {
    display: none;
}

// Jquery Highlight
.highlight {
    background-color: #ffe888;
    border-radius: 3px;
}

.error-container>.invalid-feedback {
    display: inline-block;
}

.page-header {
    font-size: 0.8rem;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #e2e5ec;
    text-transform: uppercase;
}

.breadcrumb {
    background: transparent;
    padding: 0.75rem 0.25rem;
    font-size: 0.8rem;
}

/*Icon Transform*/
.--rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    transform: rotate(90deg);
}

.--rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    transform: rotate(180deg);
}

.--rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    transform: rotate(270deg);
}

.--flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    transform: scaleX(-1);
}

.--flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    transform: scaleY(-1);
}


/* Cropper styles */
.cropper-crop-box, .cropper-view-box {
    border-radius: 50%;
}

.cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
}

.full_modal-dialog {
    width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    min-height: 100% !important;
    max-width: 100% !important;
    max-height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }

.full_modal-content {
    height: 100% !important;
    min-height: 100% !important;
    max-height: 100% !important;
}
