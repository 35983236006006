// Typography
$font-family-sans-serif: 'Red Hat Display',
sans-serif;
$font-size-base: 0.95rem;
$line-height-base: 1.6;

// Blue Gray Shadows
// $gray-100: #f2f6fc;
// $gray-200: #e0e5ec;
// $gray-300: #d4dae3;
// $gray-400: #c5ccd6;
// $gray-500: #a7aeb8;
// $gray-600: #69707a;
// $gray-700: #4a515b;
// $gray-800: #363d47;
// $gray-900: #212832;

// Gray Shadows
$gray-50: #F9FAFB;
$gray-100: #F3F4F6;
$gray-200: #E5E7EB;
$gray-300: #D1D5DB;
$gray-400: #9CA3AF;
$gray-500: #6B7280;
$gray-600: #4B5563;
$gray-700: #374151;
$gray-800: #1F2937;
$gray-900: #111827;

// Basic Colors
$white: #ffffff;
$black: #0f1522;
$blue: #005fed;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #f5365c;
$orange: #f6993f;
$yellow: #ffae36;
$green: #10B981;
$teal: #00ac69;
$cyan: #11cdef;

// Gradients
$gray-gradient: linear-gradient(62deg, #eff3fe 0%, #f3f4f6 100%);
$blue-gradient: linear-gradient(135deg, $blue 0%, #21a6f3 100%);

// Themes
$primary: $blue;
$secondary: #172b4d;

// Body
$body-bg: $gray-100;
$body-color: $gray-900;

// Border
$border-radius: .4125rem;
$border-radius-sm: .25rem;
$border-radius-md: $border-radius;
$border-radius-lg: .5rem;
$border-radius-xl: 1rem;
// $border-color:     rgba($gray-900, .135);
$border-color: mix($gray-900, #fff, 20%);

// Box Shadow
$box-shadow-sm: 0 1px 2px 0 rgba($gray-900, 0.05);
$box-shadow: 0 1px 3px 0 rgba($gray-900, 0.1),
0 1px 2px 0 rgba($gray-900, 0.06);
$box-shadow-md: 0 4px 6px -1px rgba($gray-900, 0.1),
0 2px 4px -1px rgba($gray-900, 0.06);
$box-shadow-lg: 0 10px 15px -3px rgba($gray-900, 0.1),
0 4px 6px -2px rgba($gray-900, 0.05);
$box-shadow-xl: 0 20px 25px -5px rgba($gray-900, 0.1),
0 10px 10px -5px rgba($gray-900, 0.04);
$box-shadow-xxl: 0 25px 50px -12px rgba($gray-900, 0.25);

// Buttons & Inputs
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;
$input-btn-focus-color: none;
$btn-font-weight : 500;
$input-color: $gray-900;
$input-border-color: $border-color;
$input-focus-border-color: lighten($primary, 15%);
// $input-box-shadow: $box-shadow-sm;

// Custom Control
$custom-control-indicator-size: 1.05rem;
$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-checked-border-color: $primary;
$custom-forms-transition: background-color 0.15s ease-in-out,
border-color 0.15s ease-in-out,
box-shadow 0.15s ease-in-out,
background-size 5s ease-in-out;

// Custom Checkbox
$custom-checkbox-indicator-border-radius : $border-radius / 2;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' %3e%3cpath fill='%23ffffff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z'/%3e%3c/svg%3e");

// Custom Radio
$custom-radio-indicator-icon-checked-color: $primary;
$custom-radio-checked-background-color: $white;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-radio-indicator-icon-checked-color}'/></svg>");

// Modal
$modal-content-border-width: 0px;
$modal-header-border-width: 0px;
$modal-footer-border-width: 0px;

//Cards
$card-cap-bg: 'none';
$card-border-color: $border-color;

// Breadcrumb
$breadcrumb-item-padding: 0.35rem;
$breadcrumb-divider-color: $gray-500;
// $breadcrumb-divider: "\f105";

// Badges
$badge-border-radius: .2em;
$badge-font-weight: 500;
$badge-font-size: 75%;
$badge-padding-y: .325rem;
$badge-padding-x: .475rem;
$badge-pill-padding-x: .75rem;

// Tooltip
// $tooltip-arrow-color: $dark;
// $tooltip-bg: $dark;

//DropDown
$dropdown-border-color: $border-color;

// Themes
$theme-colors: ('gray': $gray-600);

// Navbar
$navbar-light-color: $gray-900;
$navbar-light-hover-color: $gray-900;
$navbar-dark-color: $white;
$navbar-dark-hover-color: $white;

// -------------- Owner ---------------

// Sidebar
$topbar-base-height: 4rem;
$sidebar-base-width: 16rem;
$sidebar-medium-width: 13rem;
$sidebar-collapsed-width: 4.6rem;

// Text
$text-muted: rgba($black, .5);
$text-muted-light: rgba($white, .5);


// -------------- Plugins ---------------

// placeholder-loading
$ph-border-radius: $border-radius;
