.shadow-sm{
    box-shadow: $box-shadow-sm;
}
.shadow{
    box-shadow: $box-shadow;
}
.shadow-md{
    box-shadow: $box-shadow-md;
}
.shadow-lg{
    box-shadow: $box-shadow-lg;
}
.shadow-xl{
    box-shadow: $box-shadow-xl;
}
.shadow-xxl{
    box-shadow: $box-shadow-xxl;
}