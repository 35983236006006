// Sidebar
.sidebar {
    width: $sidebar-base-width;
    min-width: $sidebar-base-width;
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: width 0.3s, min-width 0.3s;

    .sidebar-body {
        padding: 0.5rem 0;
    }

    &::-webkit-scrollbar {
        width: 0.75rem;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10rem;
        border-width: 0.2rem;
        border-style: solid;
        background-clip: padding-box;
        background-color: rgba(33, 40, 50, 0.2);
        border-color: transparent;
    }

    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background: inherit;
    }

    .sidebar-brand-text {
        white-space: nowrap;
        line-height: 1.35;

        small {
            opacity: 0.9;
        }
    }

    ul {
        list-style: none;
    }

    .collapse-arrow {
        position: absolute;
        right: 0.5rem;
        top: calc(50% - 10px);
        transform: rotate(180deg);
        transition-duration: 0.25s;
        transition-property: transform;
    }

    .nav-item {

        &:last-child {
            margin-bottom: 1rem;
        }

        .menu-item {
            width: calc(100% - 1.5rem);
            display: inline-block;
        }

        .nav-link {
            position: relative;
            padding: 0.75rem 0.70rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                font-size: 1.15em;
            }

            .menu-item {
                padding-left: 0.8rem;
            }

            &:active,
            &:hover {
                // background-color: rgba($primary, 0.05);

                .menu-item {
                    padding-left: 1rem;
                }
            }

            &.active {
                &:before {
                    content: '';
                    width: 3px;
                    height: 2.1rem;
                    background: $primary;
                    position: absolute;
                    top: 0.5rem;
                    left: 0;
                    border-radius: 0px 5px 5px 0px;
                }

                .icon,
                .menu-item {
                    color: $primary;
                }
            }

            &[data-toggle="collapse"] {
                &.collapsed {
                    .collapse-arrow {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &.active {
            .nav-link {
                font-weight: 500;
            }
        }

        .collapsing {
            display: block;
            transition: $transition-collapse;
        }

        .collapse,
        .collapsing {

            .collapse-inner {
                margin: 0 0 1rem 0;

                .nav-link {
                    padding-left: 0;
                    width: calc(100% - 1.5rem);
                    align-self: unset;
                    margin-left: auto;
                    border-left: 1px solid #d7dadf;

                    &.active {
                        &:before {
                            display: none;
                        }

                        .icon,
                        .menu-item {
                            color: $body-color;
                        }
                    }
                }

                .collapse-inner .nav-link {
                    padding-left: 3.5rem;
                }
            }
        }
    }

    .sidebar-brand {
        height: $topbar-base-height;
        text-decoration: none;
        font-size: 1rem;
        font-weight: 800;
        padding: 1.5rem 1rem;
        text-transform: uppercase;
        letter-spacing: 0.05rem;
        z-index: 1;

        &-text {
            display: inline;
        }

        &-icon img {
            width: 35px;
            height: 35px;
        }
    }

    hr.sidebar-divider {
        margin: 0 1rem 1rem;
    }

    .sidebar-heading {
        padding: 1.75rem 1rem 0.75rem;
        font-size: 0.65rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        @extend .text-uppercase;
    }
}

@include media-breakpoint-up (sm) {
    .sidebar-toggled {
        #content {
            margin-left: $sidebar-collapsed-width;
        }
    }

    .sidebar {

        .menu-item {
            transition: padding .20s linear, margin-left .3s linear, opacity .3s ease, visibility .3s ease;
        }

        &.toggled {
            z-index: 9;
            position: fixed;
            width: $sidebar-collapsed-width;
            min-width: $sidebar-collapsed-width;
            height: calc(100% - #{$topbar-base-height});

            &:hover {
                width: $sidebar-base-width;
                min-width: $sidebar-base-width;
                box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;

                .menu-item {
                    margin-left: 0;
                    opacity: 1;
                    visibility: visible;
                }
            }

            &:not(:hover) {

                .collapse-arrow,
                .sidebar-brand-text {
                    display: none;
                }

                .sidebar-heading {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                    transition: padding .20s linear;
                }
            }


            .menu-item {
                margin-left: -1.5rem;
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

@include media-breakpoint-down (md) {
    .sidebar {
        width: $sidebar-medium-width;
        min-width: $sidebar-medium-width;
    }
}

@include media-breakpoint-down (xs) {
    .sidebar-toggled {
        #content {
            margin-left: 0 !important;
        }
    }

    .sidebar {
        width: $sidebar-base-width;
        min-width: $sidebar-base-width;
        position: fixed;
        top: 0;
        left: -$sidebar-base-width;
        z-index: 1000;
        transition: left 0.45s, width 0s;

        &.active {
            left: 0;

            +.overlay {
                opacity: 1;
                visibility: visible;
            }
        }

    }

    .overlay {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        width: 100vh;
        height: 100vh;
        background-color: rgba(23, 43, 77, 0.5);
        z-index: 999;
        transition: opacity .3s ease, visibility .3s ease;
    }
}

// Sidebar Light
.sidebar-light {
    .sidebar-brand {
        color: $body-color;
    }

    hr.sidebar-divider {
        border-top: 1px solid $gray-200;
    }

    .sidebar-heading {
        color: $text-muted;
    }

    .nav-item {
        .nav-link {
            color: $body-color;

            i {
                color: $gray-400;
            }

            &:active,
            &:focus,
            &:hover {
                color: $body-color;

                i {
                    color: $body-color;
                }
            }

            // Accordion
            &[data-toggle="collapse"]::after {
                color: $gray-500;
            }
        }

        &.active {
            .nav-link {
                color: $body-color;

                i {
                    color: $body-color;
                }
            }
        }
    }

    // Color the sidebar toggler
    #sidebarToggle {
        background-color: $gray-200;

        &::after {
            color: $gray-500;
        }

        &:hover {
            background-color: $gray-300;
        }
    }
}

// Sidebar Dark
.sidebar-dark {

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.25);
        border-color: rgba(0, 0, 0, 0);
    }

    .sidebar-brand {
        color: $white;
    }

    hr.sidebar-divider {
        border-top: 1px solid fade-out($white, 0.85);
    }

    .sidebar-heading {
        color: fade-out($white, 0.6);
    }

    .nav-item {
        .nav-link {
            color: fade-out($white, 0.2);

            .icon,
            .collapse-arrow {
                color: fade-out($white, 0.7);
            }

            &:active,
            &:focus,
            &:hover {
                color: $white;

                .icon,
                .collapse-arrow {
                    color: $white;
                }
            }

            // Accordion
            &[data-toggle="collapse"]::after {
                color: fade-out($white, 0.5);
            }
        }

        &.active {
            .nav-link {
                color: $white;

                i {
                    color: $white;
                }
            }
        }
    }

    // Color the sidebar toggler
    #sidebarToggle {
        background-color: fade-out($white, 0.8);

        &::after {
            color: fade-out($white, 0.5);
        }

        &:hover {
            background-color: fade-out($white, 0.75);
        }
    }

    &.toggled {
        #sidebarToggle::after {
            color: fade-out($white, 0.5);
        }
    }
}

.collapse-inner {
    .nav-link.active{
        font-weight: bold !important;
    }
}
