.center-all {
  align-items: center;
  display: flex;
  justify-content: center;
}

.center-x {
  display: flex;
  justify-content: center;
}

.center-y {
  align-items: center;
  display: flex;
}