.custom-checkbox,
.custom-radio {

    .custom-control-label {
        &:before {
            cursor: pointer;
            pointer-events: auto;
            transition: 250ms cubic-bezier(.28, .05, .36, 1);
        }

        &:hover {
            &:before {
                border: 1px solid rgba($black, .4);
                box-shadow: 0 .125rem .375rem rgba($black, .1);
            }
        }
    }

    .custom-control-input {

        &:active,
        &:active:focus,
        &:focus {
            ~.custom-control-label {
                &:before {
                    border-color: rgba($black, .5);
                    box-shadow: 0 .125rem .25rem rgba($black, .2);
                }
            }
        }

        // &:disabled {
        //   ~ .custom-control-label {
        //     &:before {
        //       cursor: auto;
        //       background-color: #efefef;
        //       box-shadow: none;
        //       opacity: .7;
        //     }
        //   }
        // }
        &:checked {
            ~.custom-control-label {
                &:before {
                    background-color: $primary;
                    border: 1px solid $primary;
                    // background-image: none;
                    box-shadow: 0 0.125rem 0.125rem rgba($black, .1);
                }

                &:after {
                    border: 0;
                    box-shadow: none;
                    opacity: 1;
                    transform: translateY(0) rotate(0.01deg);
                }
            }
        }
    }
}

// Custom Checkbox
.custom-checkbox {
    .custom-control-label {
        &::after {
            opacity: 0;
            transform: translateY(-.375rem) rotate(.01deg);
            transition-duration: 350ms;
            transition-timing-function: cubic-bezier(.4, 0, 0, 1.4);
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: 50%;
            background-size: 100%;
        }
    }

    .custom-control-input:checked,
    .custom-control-input:indeterminate {
        &~.custom-control-label {
            &::after {
                opacity: 1;
                transform: translateY(0) rotate(0.01deg);
            }
        }
    }

    .custom-control-input:indeterminate {
        &~.custom-control-label {
            &::after {
                background-size: 50%;
            }
        }
    }
}

// Custom Radio
.custom-radio {
    .custom-control-label {
        &::after {
            transition: background-size 0.15s ease-in-out;
            background-size: 0%;
        }
    }

    .custom-control-input:checked {
        &~.custom-control-label {
            &::before {
                background-color: $custom-radio-checked-background-color;
            }

            &::after {
                transition: background-size 0.15s ease-in-out;
                background-size: 80%;
            }
        }
    }
}

// Custom Switch
.custom-switch {

    .custom-control-label {
        padding-left: 5px;

        &:before {
            border: 1px solid rgba($black, .25);
            box-shadow: 0 .125rem .125rem rgba($black, .08);
            border-radius: 50rem;
            height: 1.25rem;
            left: -39px;
            top: 0;
            width: 2.10rem;
        }

        &:after {
            background-image: none;
            background-color: $white;
            border-radius: 50%;
            border: 1px solid rgba($black, .3);
            box-shadow: 0 .125rem .25rem rgba($black, .2);
            left: -36px;
            height: 1rem;
            opacity: 1;
            top: 2px;
            width: 1rem;
            transform: translateX(0) rotate(.01deg);
            transition: 400ms cubic-bezier(.25, .4, 0, 1);
        }
    }

    .custom-control-input {
        &:not(:disabled):hover {
            ~.custom-control-label {

                &:before {
                    border-color: rgba($black, .4);
                    box-shadow: 0 .125rem .375rem rgba($black, .1);
                    cursor: pointer;
                }

                &:after {
                    cursor: pointer;
                }
            }
        }

        &:checked,
        &:checked:focus {
            ~.custom-control-label {

                &:before {
                    background-color: $primary;
                    border-color: $primary;
                }

                &:after {
                    border-color: transparent;
                    background-color: $white;
                }
            }
        }

        &:focus {
            ~.custom-control-label {
                &:before {
                    border-color: rgba($black, .4);
                }
            }
        }

        // &:disabled {

        //     // Pill background
        //     ~.custom-control-label {
        //         &:before {
        //             box-shadow: none;
        //             opacity: .6;
        //         }

        //         // Circle
        //         &:after {
        //             background-color: transparent;
        //             box-shadow: none;
        //             opacity: .6;
        //         }
        //     }
        // }
    }
}

.custom-switch-end {
    padding-left: 0;

    .custom-control-label {
        width: 100%;

        &:after {
            left: inherit;
            right: 0;
        }

        &:before {
            left: inherit;
            right: 1rem;
        }
    }
}
