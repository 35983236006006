// Border radius
.rounded-sm {
	border-radius: $border-radius-sm !important;
}

.rounded {
	border-radius: $border-radius-md !important;
}

.rounded-lg {
	border-radius: $border-radius-lg !important;
}

.rounded-xl {
	border-radius: $border-radius-xl !important;
}

// Border thickness
@for $i from 0 through 5 {
	.border-#{$i} {
    	border-width: #{$i}px !important;
	}
	.border-top-#{$i} {
    	border-top-width: #{$i}px !important;
	}
	.border-right-#{$i} {
    	border-right-width: #{$i}px !important;
	}
	.border-bottom-#{$i} {
    	border-bottom-width: #{$i}px !important;
	}
	.border-left-#{$i} {
    	border-left-width: #{$i}px !important;
	}
}
